import { useState } from "react";
import 'bootswatch/dist/darkly/bootstrap.min.css';

function App() {
  const [value, setValue] = useState('');
  const [list, setList] = useState([]);

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };
  
  const handleOnClick = () => {
    if (value.trim()) {
      setList([...list, value]);
      setValue('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOnClick(); // Llama a la función de agregar tarea
    }
  };

  const handleDelete = (index) => {
    const newList = list.filter((item, i) => i !== index);
    setList(newList);
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '600px' }}>
      <h1 className="text-light text-center mb-4">Lista de Tareas</h1>
      
      <div className="input-group mb-3">
        <input
          type="text"
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown} // Agregar evento para la tecla Enter
          placeholder="Escribe una tarea..."
          className="form-control form-control-lg"
          style={{ borderRadius: '10px' }}
        />
        <button 
          onClick={handleOnClick} 
          className="btn btn-success btn-lg ms-2"
          style={{ borderRadius: '10px' }}
        >
          Agregar
        </button>
      </div>
      
      <ul className="list-group">
        {list.length > 0 ? (
          list.map((item, i) => (
            <li key={i} className="list-group-item d-flex justify-content-between align-items-center bg-secondary text-light mb-2" style={{ borderRadius: '8px' }}>
              <span>{item}</span>
              <button onClick={() => handleDelete(i)} className="btn btn-danger btn-sm" style={{ borderRadius: '5px' }}>
                Eliminar
              </button>
            </li>
          ))
        ) : (
          <p className="text-muted text-center">No hay tareas</p>
        )}
      </ul>
    </div>
  );
}

export default App;
